@import "../../../styles/lib";

.Footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 64px 0;
  background-color: #1d2939;

  @include tablet {
    padding: 39px 30px;
  }

  &__main {
    background-color: white;

    &__top {
      padding: 72px 0;
      margin-bottom: -10vh;
      background-color: white;
      margin-top: 10vh;

      @include tablet {
        padding: 30px;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        h2 {
          font-size: 34px;
          font-weight: 700;
          line-height: 44px;
          color: #101828;
          margin-bottom: 18px;
          @include tablet {
            font-size: 28px;
            line-height: 37px;
            font-weight: 700;
          }
        }
        p {
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          color: #475467;
          @include tablet {
            font-size: 17px;
            line-height: 27px;
            font-weight: 500;
          }
        }
        &_btns {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          button {
            border: 3px solid #1d3682;
            color: #1d3682;
          }
          .Header--bottom--buttons-rev {
            background-color: #1d3682 !important;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    &_top {
      display: flex;
      justify-content: space-between;
      &_wr {
        padding: 50px 0;
        display: flex;
        justify-content: space-between;
        @include tablet {
          flex-direction: column;
          gap: 20px;
          padding: 60px 20px;
        }
      }
      
      @include tablet {
        flex-direction: column;
      }
      h2 {
        font-size: 30px;
        line-height: 38px;
        font-weight: 700;
        @include tablet {
          font-size: 28px;
          line-height: 37px;
          font-weight: 700;
        }
      }
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #eaecf0;
        @include tablet {
          font-size: 17px;
          line-height: 27px;
          font-weight: 500;
        }
      }
      &_labels {
        display: flex;
        flex-direction: column;
      }
      &_btn {
        .Header--bottom--buttons button {
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          margin-top: 34px;
        }
      }
    }
    &_middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      &_tabs {
        margin-top: 40px;
        @include tablet {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 25px;
        }
        span {
          margin: 0 16px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          &:hover {
            cursor: pointer;
          }
          @include tablet {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            margin-bottom: 10px;
          }
        }
      }
    }
    &_bottom {
      display: flex;
      justify-content: space-between;
      @include tablet {
        flex-direction: column;
        align-items: center;
      }
      &-right {
        @include tablet {
          margin-top: 12px;
        }
        a {
          margin: 0 6px;
          text-decoration: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #d0d5dd;
          @include tablet {
            font-size: 16px;
            font-weight: 24px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      &-left {
        color: #d0d5dd;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        @include tablet {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  &__divider {
    width: 100%;
    margin: 50px 0;
    border-bottom: 1px solid #475468;
    @include tablet {
      margin: 40px 0;
    }
  }
}
.cst-bck {
  color: white;
  background-color: #101726;
}
