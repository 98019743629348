@import "../../styles/lib.scss";

.Categories {
  &__wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    @include tablet {
      padding: 0px 30px;
    }
    &__top {
      margin-bottom: 52px;
      @include tablet {
        margin-bottom: 40px;
      }
      h2 {
        font-size: 36px;
        line-height: 44px;
        font-weight: 700;
        color: #101828;
        margin-bottom: 20px;
        @include tablet {
          margin-bottom: 18px;
          font-size: 28px;
          font-weight: 700;
          line-height: 37px;
        }
      }
      p {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: #475467;
        @include tablet {
          font-size: 17px;
          line-height: 27px;
          margin-bottom: 0;
        }
      }
    }
    &__bottom {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(4, 1fr);
      @include tablet {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 17px;
      }
    }
    .SlideShow {
      &--category {
        width: 100%;
        &-desc {
          width: 100%;
          text-align: start;
          @include tablet {
            text-align: start;
          }
        }
      }
      &-box {
        width: 100%;
        max-width: 100%;
        margin: unset;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
