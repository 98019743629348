@import "../../styles/lib.scss";

.Partners {
  background-color: white;
  padding: 136px 0;

  @include tablet {
    padding: 50px 30px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
    }
    h2 {
      font-size: 48px;
      font-weight: 700;
      line-height: 60px;
      color: #1f2025;
      @include tablet {
        font-size: 28px;
        line-height: 37px;
        font-weight: 700;
      }
    }
    p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      color: #475467;
      @include tablet {
        font-size: 17px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    &_left {
      width: 50%;
      @include tablet {
        width: 100%;
      }
    }
    &_right {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 20px;
      width: 50%;

      @include tablet {
        width: 100%;
        margin-top: 50px;
        align-items: center;
      }

      @include mobile {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

      }

      
      img {
        border: 1px solid #ccc;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
        max-width: 100%;
        @include tablet {
          max-width: 300px;
          height: 300px;
        }

        @include mobile {
          max-width: 100%;
          height: 100%;
          display: none;
          
        }
      }
    }
  }
  .Header--bottom--buttons {
    margin-top: 40px;
    button {
      background-color: #1d3682;
      color: white;
    }
  }
}
