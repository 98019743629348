@import "../../styles/lib.scss";

.ContactUs {
  background-color: white;
  margin-top: -10vh;
  padding-top: 96px;

  @include tablet {
    padding-top: 86px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .Contact__wrapper-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 97px 0;
    background-color: #fbfbfb;

    h2{
      font-size: 40px;
    }
    
    @include tablet {
      padding: 60px 30px;
      margin-right: -30px;
    }

    .ContactForm {
      max-width: 480px;
    }

    .Header--bottom--buttons {
      margin-top: 32px;
      button {
        background-color: #1d3682;
        color: white;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
    .form-group {
      margin-top: 20px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 96px;
    &__top {
      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #1d3682;
        @include tablet {
          font-size: 16px;
          line-height: 24px;
        }
      }
      h1 {
        font-size: 48px;
        line-height: 60px;
        font-weight: 700;
        color: #101828;
        margin-bottom: 24px;
        @include tablet {
          font-size: 36px;
          line-height: 46px;
        }
      }
      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #475467;
        @include tablet {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 0;
        }
      }
    }
    &__middle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 96px;

      @include tablet {
        margin-top: 50px;
        flex-direction: column;
      }

      &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include tablet {
          margin-bottom: 44px;
        }

        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 666px;
          border: 8px solid #f6f6f6;
          padding: 7px;
          margin-bottom: 20px;
        }
        span {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #101828;
        }
        p {
          margin-top: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #475467;
          margin-bottom: 20px;
        }
        label {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #1d3682;
        }
      }
    }
  }
}
